export const FEATURES = Object.freeze({
  ASYNC_PAC_VALIDATE_CONFIGURATION: 'ASYNC_PAC_VALIDATE_CONFIGURATION',
  VUE_APP_BOM_SHOW_AMBIGUOUS_TOGGLE: 'VUE_APP_BOM_SHOW_AMBIGUOUS_TOGGLE',
  VUE_APP_SHOW_RESULTS_LATER_TOGGLE: 'VUE_APP_SHOW_RESULTS_LATER_TOGGLE',
  VUE_APP_TEST_CASES_PIN_TOGGLE: 'VUE_APP_TEST_CASES_PIN_TOGGLE'
})

export const ENV_TOGGLES = {
  [FEATURES.ASYNC_PAC_VALIDATE_CONFIGURATION]: process.env.VUE_APP_ASYNC_PAC_VALIDATE_CONFIGURATION === 'true',
  [FEATURES.VUE_APP_BOM_SHOW_AMBIGUOUS_TOGGLE]: process.env.VUE_APP_BOM_SHOW_AMBIGUOUS_TOGGLE === 'true',
  [FEATURES.VUE_APP_SHOW_RESULTS_LATER_TOGGLE]: process.env.VUE_APP_SHOW_RESULTS_LATER_TOGGLE === 'true',
  [FEATURES.VUE_APP_TEST_CASES_PIN_TOGGLE]: process.env.VUE_APP_TEST_CASES_PIN_TOGGLE === 'true'
}

export const isEnabled = (key, toggles = null) => (toggles ? toggles[key] : ENV_TOGGLES[key])
